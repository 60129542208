import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./rejectedLead.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";

import { deletePackage_api } from "../api/package";
import { notificationHandler } from "../../utils/Notification";
import Cookies from "js-cookie";
import { getRejectLeadForAdmin_api, rejectedLeadStatus_api } from "../api/rejectedLead";
import Reminder from "../Dashboard/Reminder";
import RemarkModal from "./RemarkModal";
import xlsx from "json-as-xlsx";
import { BiSearch } from "react-icons/bi";
import { getVendor_api } from "../api/vendor";
const RejectedLeadList = () => {

    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [rejectLead, setrejectLead] = useState([]);
    const [leadId , setLeadId] = useState()
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState();
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [leadStatus , setLeadStatus] = useState()
    const [type, setType] = useState("")
    const [open, setOpen] = useState(false)
    const [search, setsearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setendDate] = useState("")
    const [vendor , setVendor] = useState()
    const [vendorId , setVendorId] = useState()
    const role = Cookies.get("role")
   let vendId = Cookies.get("userId")
    
console.log(vendId, "vendid")

    // console.log(role != "Super Admin" && role!="Admin")

    useEffect(() => {
        fetchrejectLeadFunc();
    }, [pageCount, type , search , vendorId]);

    async function fetchrejectLeadFunc(data) {
        setisLoading(true);
        try {
            let temp;
      if (data == "reset") {
        setsearch("");
        setStartDate("");
        setendDate("");
        setVendorId("")
        temp = {
          page: pageCount,
          limit: 10,
          search: "",
          startDate: "",
          endDate: "",
         vendorId:''
        };
      } else {
        temp = {
          page: pageCount,
          limit: 10,
          search: search.trim(),
          startDate: startDate,
          endDate: endDate,
          vendorId:role=="Vendor"?vendId:vendorId?vendorId:""
        };
      }
            let res = await getRejectLeadForAdmin_api(temp);
            if (res.data.status) {
                // only pending status show
                // setrejectLead(res?.data?.data?.rejectLead?.filter((elem)=>elem.status=="Pending" ));
                setrejectLead(res?.data?.data?.rejectLead );
                setpageLength(res?.data?.totalPage)
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            setisLoading(false)
            console.log(error);
        }
    }

    const fetchallVendorFunc = async (data) => {
        setisLoading(true);
        try {
           let temp = {
                page: pageCount,
                limit: 100,
                search: "",
                startDate: "",
                endDate: "",
               location:'',
               package:'',
               verify:''
              };
            
            let res = await getVendor_api(temp);
            if (res.data.status) {
                setVendor(res.data.data.vendors);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=>{
            fetchallVendorFunc()
    },[])


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteuserFunc() {
        setisLoading(true);

        try {
            let res = await deletePackage_api(deleteId);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setdeletePopup(false);
                fetchrejectLeadFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }


    const rejectedLeadFunc = async (e, row) => {
        // let data = {
        //     status: e.target.value
        // }
        setLeadStatus(e.target.value)
        setLeadId(row?._id)
        setOpen(true)
        // try {
        //     let res = await rejectedLeadStatus_api(data, row?._id)
        //     if (res.data.status) {
        //         fetchrejectLeadFunc()
        //         notificationHandler({
        //             type: "success",
        //             msg: "Status Update Successfully",
        //         });
        //         setLeadId(row?._id)
        //         setOpen(true)
        //     } else {
        //         notificationHandler({ type: "success", msg: res.data.message });
        //     }
        // } catch (error) {
        //     notificationHandler({ type: "danger", msg: error.message });
        //     console.log(error);
        // }
    }

    let settings = {
        fileName: "Rejected File", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };
    
      const exportFunc = () => {
        if (rejectLead?.length < 1) {
          return notificationHandler({
            type: "danger",
            msg: "Rejected List is empty!",
          });
        }
        let data = [
          {
            sheet: "Customers",
            columns: [
              { label: "Sr.No", value: (row) => (row._id ? row._id : "") },
              { label: "Lead Name", value: (row) => (row?.lead?.name ? row?.lead?.name : "") },
              { label: "Vendor Reason", value: (row) => (row.reason ? row.reason : "") },
              { label: "Admin Remark", value: (row) => (row.remark ? row.remark : "") },
              { label: "Admin Action", value: (row) => (row?.status=="Success"?"Verified":row?.status=="Pending"?"Pending":"Rejected" ? row?.status=="Success"?"Verified":row?.status=="Pending"?"Pending":"Rejected" : "") },
              
            ],
            content: rejectLead,
          },
        ];
        try {
          xlsx(data, settings, callback);
        } catch (error) {
          console.log(error);
        }
      };
    
      let callback = function (sheet) {
        console.log("Download complete:", sheet);
      };

    return (
        <div className="">
            <div className={s["article-list-title"]}>
                <h3>Rejected Lead List</h3>
            <div
              className={s["search-box"]}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search users by name..."
              />
            </div>
            <div className={s["user-list-search"]}>
            
            <div style={{ display: "flex" }}>
            <div className="search-123">
            <select className="search-inp1" value={vendorId} style={{width:"150px"}} onChange={(e) => setVendorId(e.target.value)}>
              <option value="">Vendor List</option>
              {
                vendor?.map((elem, id) => {
                  return (
                    <option key={id} value={elem._id}>{elem?.userName}</option>

                  )
                })
              }
            </select>
          </div>
          <div className="search-123">
            {/* <span style={{fontSize:"x-small"}}>Filter by date From and To</span> */}
            <div style={{ display: "flex" }}>
              <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="search-inp1" />
              <input type="date" value={endDate} onChange={(e) => setendDate(e.target.value)} className="search-inp1" />
              <button className="search-inp1" onClick={() => fetchrejectLeadFunc()}>Apply</button>
              <button className="search-inp1" style={{ border: "1px solid #fff" }} onClick={() => fetchrejectLeadFunc("reset")}>Reset Filter</button>

            </div>
          </div>
          {role !== "Vendor" && <div className="search-123">
              <label className={s["title"]} onClick={() => exportFunc()}>
                {/* {exportBtnLoading ? <CircularProgress size={24} /> : "Export"} */}
                Export
              </label>
            </div>}
        </div>
          </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Lead Data</StyledTableCell>
                            {(role == "Super Admin" || role == "Admin") && (<StyledTableCell align="center">Vendor Data</StyledTableCell>)}
                            <StyledTableCell align="center">Vendor Reason</StyledTableCell>
                            <StyledTableCell align="center">Admin Remark</StyledTableCell>
                            <StyledTableCell align="center">Rejected date</StyledTableCell>
                            {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                            {(role == "Super Admin" || role == "Admin") && (<StyledTableCell align="center">Action</StyledTableCell>)}
                            {(role != "Super Admin" && role != "Admin") && (<StyledTableCell align="center">Admin Action</StyledTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rejectLead?.map((row) => (
                            <StyledTableRow key={row._id}>
                                <StyledTableCell>
                                    <div>
                                        <p><b>Name:</b>{row?.lead?.name}</p>
                                        {(role == "Super Admin" || role == "Admin") && (<p><b>Location:</b>{row?.lead?.location?.location}</p>)}
                                        {(role == "Super Admin" || role == "Admin") && (<p><b>Mobile NO.:</b>{row?.lead?.mobile}</p>)}
                                    </div>
                                </StyledTableCell>
                                {(role == "Super Admin" || role == "Admin") && (<StyledTableCell>
                                    <div>
                                        <p><b>Name:</b>{row?.rejectedBy?.userName}</p>
                                        <p><b>Mobile NO.:</b>{row?.rejectedBy?.mobile}</p>
                                    </div>
                                </StyledTableCell>)}
                                <StyledTableCell>{row?.reason} </StyledTableCell>
                                <StyledTableCell>{row?.remark} </StyledTableCell>
                                <StyledTableCell>{new Date(row?.createdAt).toLocaleString()}</StyledTableCell>
                                {(role == "Super Admin" || role == "Admin")? (<StyledTableCell align="center">
                                    <select className="remark-design"
                                        value={row.status}
                                        onChange={(e) => rejectedLeadFunc(e, row)}
                                    >
                                        <option value="Pending">Pending</option>
                                        <option value="Success">Verified</option>
                                        <option value="Reject">Reject</option>
                                    </select>
                                </StyledTableCell>):
                                <StyledTableCell>{row?.status=="Success"?"Verified":row?.status=="Pending"?"Pending":"Rejected"} </StyledTableCell>
                                }
                                {/* <StyledTableCell align="center">
                                    <CiEdit
                                        onClick={() =>
                                            navigate("/add-package", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <MdDelete
                                        onClick={() => {
                                            setdeletePopup(true);
                                            setcurrentGroup(row);
                                            setdeleteId(row._id);
                                        }}
                                        style={{ fontSize: "1rem", color: "var(--clr-primary)", cursor: "pointer" }}
                                    />
                                </StyledTableCell> */}
                            </StyledTableRow>
                        ))}

                    </TableBody>
                </Table>
                {rejectLead?.length <= 0 && <DataNotFound />}
                {rejectLead?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>
            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.skill_name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={() => deleteuserFunc()}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <RemarkModal open={open} setOpen={setOpen} leadId={leadId} leadStatus={leadStatus}/>
            <Loder loading={isLoading} />
            <Reminder/>
        </div >
    )
}
export default RejectedLeadList;
